.wrapper {
  margin: 32px auto 0 auto;
  max-width: 758px;
  position: relative;
}

.backButton {
  position: absolute;
  left: -80px;
  top: 30px;
}
