.bnplListContainer {
  position: relative;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 460px;
  height: fit-content;
  margin: 16px auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*width: 758px;*/
  overflow-y: scroll;
  padding-bottom: 20px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-1);
  padding: 16px;
  position: static;
  outline: none;
  background-color: var(--black);
  color: var(--white);
  border: none;
  margin: 20px 32px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.149284);
  border-radius: 3px;
}

.button:disabled {
  background-color: var(--grey-text);
}

.bnplListTitle {
  margin-left: 24px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.bnplListSubtitle {
  margin-left: 24px;
  margin-top: 8px;
  color: var(--grey-text);
}

.bnplsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;
  margin-top: 24px;
  width: 93%;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
