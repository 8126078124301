* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
  /* align-items: center; */
}

.flex-start {
  align-items: start !important;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-90 {
  width: 90%;
}

.h-half {
  height: 50%;
}

.flex-col {
  flex-direction: column;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-8 {
  margin-right: 8px;
}

.mr-8em {
  margin-right: 8em;
}

.ml-40 {
  margin-left: 40px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-5_per {
  margin-top: 5%;
}

.mt-70 {
  margin-top: 70px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-60 {
  margin-bottom: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 6px;
}

.box {
  padding: 30px 50px 30px 50px;
  border-radius: 8px;
  border: 1px solid #dfe3e9;
}

.box-invoice {
  padding: 20px 80px;
  border: 1px solid #dfe3e9;
  margin: 0px 110px;
  border-radius: 8px;
}

.border-right {
  border-right: 1px solid black;
}

.colored-btn {
  color: white;
  background-color: black;
  border: 1px solid black;
  padding: 5px 20px;
  font-size: small;
  border-radius: 7px;
}

.disable {
  background-color: grey;
  color: black;
  border: 1px solid black;
  padding: 5px 20px;
  font-size: small;
  border-radius: 7px;
}

.pointer {
  cursor: pointer;
}

.padding-large {
  padding: 10px 30px;
}

.font-large {
  font-size: large;
}
.font-xlarge {
  font-size: x-large;
}

.soft-label {
  color: #4e5862;
  margin-top: 23px;
  margin-bottom: 6px;
  font-size: small;
}

.payment-info-logo {
  border: 1px solid #00000030;
  padding: 40px;
  width: 90%;
  /* height: 100px; */
  border-radius: 10px;
}
.MuiTextField-root.country-code .MuiOutlinedInput-root .MuiOutlinedInput-input {
  text-align: center;
}

.override-mui-style .MuiFormLabel-root {
  display: block;
  transform-origin: top left;
  color: #4a5a68;
  font-size: 16px;
  line-height: 14px;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
