.checkoutContainer {
  position: relative;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 460px;
  height: fit-content;
  margin: 16px auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 758px;
  overflow-y: scroll;
  padding-bottom: 20px;
  font-size: 16px;
}

.addressForm {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 2.2rem;
  width: 90%;
}

.addressForm label {
  margin: 0.4rem;
  font-weight: 400;
}

.addressForm label input {
  padding: 0.6rem;
  border: 1px solid #cdd1d5;
  border-radius: 5px;
  width: 100%;
}

.signleLineField {
  display: flex;
  justify-content: space-between;
}

.widthFit {
  width: 315px !important;
}

.marginRFit {
  margin-right: 0.4rem;
}

.formError {
  color: #cc0033;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 0;
}
