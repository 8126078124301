.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  background: #ffffff;
  border-radius: 4px;
  margin: 32px auto 0;
  max-width: 758px;
}

.errorContainer img {
  display: block;
  margin: 0 auto 24px;
}

.errorContainer h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
}

.errorContainer p {
  color: var(--grey-text);
  font-size: 16px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-1);
  padding: 16px;
  position: static;
  outline: none;
  background-color: var(--black);
  color: var(--white);
  border: none;
  margin: 20px 32px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.149284);
  border-radius: 3px;
}
