:root {
  /* // Font Family */
  --font-family-1: 'AvenirNext';

  /* // Color */
  --grey: #f8f8fb;
  --grey-2: #f2f2f2;

  --grey-text: #828282;
  --black: #000000;
  --white: #ffffff;
}
