.orderAmountCard {
  position: relative;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* height: 72px; */
  margin: 0 auto 0;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  width: 758px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.orderAmountCard .orderAmountTitle {
  font-size: 16px;
  color: var(--grey-text);
  margin-left: 24px;
  text-transform: capitalize;
}

.orderAmountCard .orderAmount {
  font-size: 24px;
  margin-left: 8px;
  font-weight: 600;
}

.orderAmountCard .description {
  font-size: 14px;
  color: var(--grey-text);
  margin-left: 24px;
}

.orderAmountCard .hr {
  margin: 10px 22px 5px 22px;
}

.amount {
  padding: 0.6rem;
  margin-left: 0rem;
  border: 1px solid #00000061;
  border-radius: 5px;
  width: 10%;
}
