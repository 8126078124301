@media screen and (max-width: 1060px) {
  .m-main-head {
    font-size: 14px !important;
  }

  .m-m0 {
    margin: 0px !important;
  }
  .icon {
    width: 25px !important;
    height: 15px !important;
  }
  .m-flex-row {
    flex-direction: row;
  }

  .m-head {
    font-size: 24px !important;
  }

  .m-divider {
    height: 0px !important;
    background-color: rgba(128, 128, 128, 0.233);
    margin: 0px !important;
  }

  .header {
    height: 16vh !important;
    max-height: 150px !important;
    padding: 6px 20px 6px 20px !important;
  }
  .merchant-logo {
    height: 16vh !important;
    max-height: 150px !important;
  }

  .merchant-logo img {
    max-width: 150px !important;
    max-height: 150px !important;
  }
}

@media screen and (max-width: 750px) {
  .goog-te-gadget {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 500px) {
  .m-w-60 {
    width: 60% !important;
  }

  .m-head {
    font-size: 20px !important;
    margin-left: 2px !important;
  }

  .m-ml {
    margin-left: 10px;
  }
}

@media screen and (max-width: 700px) {
  .m-main-head {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 390px) {
  .m-main-head {
    font-size: 8px !important;
  }
}

.header {
  width: 100%;
  height: 12vh;
  max-height: 150px;
  background-color: var(--white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-sizing: border-box;
  padding: 6px 40px 6px 40px;
}

.headerBannerDivider {
  width: 1px;
  height: 40px;
  background-color: rgba(128, 128, 128, 0.233);
  margin: 0 16px;
}

.headerBannerGuarantee {
  display: flex;
  flex-direction: row;
}

.headerBannerGuarantee img {
  margin-right: 10px;
}

.merchant-logo {
  height: 12vh;
  max-height: 150px;
  min-width: 120px !important;
}

.headerBannerGuarantee div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.headerBannerGuarantee div p:first-of-type {
  font-size: 12px;
  font-weight: bold;
}
.headerBannerGuarantee div p:nth-of-type(2) {
  font-size: 10px;
  color: var(--grey-text);
}

.icon {
  width: 30px;
  height: 25px;
  margin-right: 10px;
}

.phone-icon {
  margin-top: 5px;
  height: 14px;
}

.w-32 {
  width: 32%;
}

.space-between {
  justify-content: space-between;
}

#google_translate_element div:nth-child(2) {
  display: none;
}

/* .goog-te-combo:first-of-type {
  display: none;
} */

#google_translate_element select:nth-child(2) {
  display: none;
}

@media screen and (max-width: 500px) {
  #google_translate_element {
    display: none;
  }
}
