body {
  padding: 0;
  margin: 0;
  background-color: var(--grey);
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: var(--font-family-1);
}

h1,
h2,
h3,
table,
tr,
td,
p {
  margin: 0;
}

table {
  border: none;
  border-spacing: 0;
}
a,
a:active,
a:active {
  color: var(--black);
  text-decoration: none;
}

a[data-underline='true'] {
  text-decoration: underline;
}

/* Fonts */

@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'),
    url(../../assets/fonts/AvenirNext/AvenirNext-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'),
    url(../../assets/fonts/AvenirNext/AvenirNext-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'),
    url(../../assets/fonts/AvenirNext/AvenirNext-DemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'),
    url(../../assets/fonts/AvenirNext/AvenirNext-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'AvenirNext';
  src: local('AvenirNext'),
    url(../../assets/fonts/AvenirNext/AvenirNext-Heavy.ttf) format('truetype');
  font-style: normal;
  font-weight: 900;
}
