.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: fit-content;
  background-color: var(--white);
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.041);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  max-height: 64px;
  z-index: 2;
}

.footer a,
.footer p {
  font-size: 12px;
}

.footerCopywright {
  color: var(--grey-text);
  margin: 10px 20px;
}

.footerMisc {
  display: flex;
  justify-content: space-evenly;
  min-width: 300px;
  margin: 10px 20px;
}

.footer .divider {
  width: 1px;
  height: 20px;
  background-color: rgba(128, 128, 128, 0.233);
  margin: 0 10px;
}

@media screen and (max-width: 500px) {
  .footerMisc {
    min-width: 200px !important;
  }

  .footerCopywright {
    font-size: 10px !important;
  }
}
