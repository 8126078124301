.bnplListItemContainer {
  width: 100%;
  height: 59px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  margin-top: 8px;
  padding-bottom: 20px;
}

.bnplImage {
  max-width: 81px;
  max-height: 90px;
  min-height: 26px;
  min-width: 48px;
  width: 70px;
  height: 50px;
  object-fit: contain;
}

.bnplListItemContainerFull {
  width: 100%;
  /* height: 301px; */
  border: 1px solid var(--black);
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  margin-top: 8px;
  padding-bottom: 20px;
}

.bnplListItemLogo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #dddddd;
  margin-right: 10px;
}

.bnplHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 59px;
  /* padding: 0 10px; */
}

.bnplHeader img {
  margin-right: 10px;
}

.bnplDetails {
  background-color: var(--grey-2);
  border-radius: 4px;
  /* min-height: 226px; */
  height: fit-content;
  border-radius: 4px;
  margin: 0 10px;
}

.bnplDetails .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 16px;
}

.content div {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
}

.surchargeConsentBox {
  padding: 4px;
  background-color: #ede0b9;
  border: thin dashed #999999;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
  border-radius: 5px;
  height: fit-content;
}

.record {
  margin-top: 8px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.fieldTitle {
  font-size: 14px;
  font-weight: 600;
  align-self: center;
}

.fieldValue {
  font-size: 14px;
  margin-left: 15px;
  align-self: center;
}

.bnplTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 59px;
  padding: 0 10px;
}
.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--black);
  margin-right: 10px;
  padding: 2px;
}
.checkbox span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.checkboxActive {
  background-color: var(--black);
}

.bnplCheckoutConfig img {
  max-width: 100%;
  height: auto;
  min-width: 100%;
  width: auto;
}

.bnplCheckoutConfig p {
  width: 100%;
}
