.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  background: #ffffff;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 32px auto 0;
  max-width: 758px;
}

.messageContainer img {
  display: block;
  margin: 0 auto 24px;
}

.messageContainer .loader {
  animation: rotation 2s infinite linear;
}

.messageContainer h3 {
  margin-bottom: 8px;
  font-size: 24px;
}

.messageContainer p {
  color: var(--grey-text);
  font-size: 16px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
