.invoice-and-tip {
  justify-content: space-between;
}

.main-checkout {
  width: 75%;
  margin-top: 60px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.invoice-description {
  width: 40%;
}

.color-light {
  color: #4a5a68;
}

.checkout-address-form {
  width: 70%;
}

.order-summary-total-div {
  margin-bottom: 6px;
  justify-content: space-between;
}

.shadded-box {
  background-color: #eaecee;
  padding: 20px;
  border-radius: 8px;
}

.checkout-item {
  width: 80px;
  height: 80px;
  background-color: black;
  border-radius: 10px;
}

.m-margin {
  margin-right: 40px !important;
}

.order-summary-total-div {
  margin-bottom: 6px;
  justify-content: space-between;
}
#outlined-adornment-order-amount {
  border-left: 1px solid #a8a8aa;
  padding-left: 10px;
}
.little-bold {
  font-size: medium;
  font-weight: 700;
}

.extra-little-bold {
  font-size: larger;
  font-weight: 500;
}
.payment-info-div {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.payment-info-summary-logo {
  width: 100%;
  height: 200px;
}

.status-SUCCESSFUL {
  background-color: #bef6bf;
  color: #40673a;
}

.status-PENDING {
  background-color: #ecb371;
  color: #ffffff;
}

.status-UNPAID {
  background-color: #f8afa8;
  color: #8a1a19;
}

.status-CANCELED {
  background-color: #ffeb95;
}

.status-REFUND {
  background-color: #bcd8fe;
  color: #0f2d86;
}

.status-DECLINED {
  background-color: #de3535;
  color: #ffffff;
}

.status-ERROR {
  background-color: #de3535;
  color: #ffffff;
}

.status-btn {
  border: 0px;
  border-radius: 8px;
}

.retry-btn {
  background-color: #186aff;
  border: 0px;
  border-radius: 8px;
  color: #ffffff;
}

.drpodown-mr {
  margin-right: 40px !important;
}

.phone-number {
  padding: 20px 0px;
}

.big-screen-off {
  display: none;
}

@media screen and (max-width: 1060px) {
  .mobile-w-full {
    width: 100% !important;
  }
  .mobile-flex {
    flex-direction: column;
    justify-content: center;
  }
  .shadded-box {
    margin-top: 70px;
    margin-left: 0px !important;
  }
  .mh-2 input {
    height: 4px;
  }
  .mh-2 label {
    font-size: small;
    margin: -3px;
    line-height: 14px;
  }
  .drpodown-mr {
    margin-right: 30px !important;
  }
  #m-dropdown {
    min-height: 3px;
    height: 3px;
  }
  .m-tile {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .m-span {
    font-size: 12px;
  }
  .mw-half {
    width: 50% !important;
  }
  .mw-full {
    width: 100% !important;
  }
  .mw-100 {
    width: 100px !important;
  }
  .mw-20 {
    width: 20%;
  }
  .m-fields {
    flex-direction: column;
  }
  .m-margin-top {
    margin-top: 20px !important;
  }
  .m-margin {
    margin-right: 0px !important;
  }
  .m-h5-height {
    height: 30px;
  }
  .m-main-label-size {
    font-size: 16px;
  }
  .m-main-label-size input {
    font-size: 14px;
  }

  .m-submain-label-size {
    font-size: 14px;
  }
  .mp-0 {
    padding: 0px !important;
  }
  .mm-0 {
    margin: 0px !important;
  }
  .m-ml-5 {
    margin-left: 5px;
  }

  .m-desc {
    padding: 0px 50px !important;
  }
}

@media screen and (min-width: 950px) {
  .m-items {
    width: 50%;
  }
  .m-items-quantity {
    width: 28%;
  }
}

@media screen and (max-width: 1060px) {
  .makeStyles-phoneNumberBox-11 {
    max-height: 38px !important;
  }
  .m-phone-number {
    padding: 10px 0px !important;
  }
}

@media only screen and (min-width: 1060px) and (max-width: 1500px) {
  .m-w-90 {
    width: 90% !important;
  }
  .m-w-20 {
    width: 20% !important;
  }
  .m-w-70 {
    width: 70% !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .flex-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .shadded-box {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .m-m-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .m-font {
    font-size: 11px;
  }
  .m-w-full {
    width: 100% !important;
  }
  .font-large {
    font-size: medium !important;
  }
  .font-xlarge {
    font-size: medium !important;
  }
  .m-font-weight {
    font-weight: 700 !important;
  }
  .m-0 {
    margin: 0px !important;
  }
  .small-screen-on {
    display: flex;
  }
  .small-screen-off {
    display: none;
  }
  .m-center {
    justify-content: center !important;
  }
  .m-mb-30 {
    margin-bottom: 60px;
  }
  .m-w-90 {
    width: 90% !important;
  }
}

@media screen and (max-width: 500) {
  .font-small {
    font-size: 12px !important;
  }
  .m-mb-60 {
    margin-bottom: 60px;
  }
}

@media print {
  @page {
    size: 300mm 290mm;
    margin: 5mm;
  }

  #printBtn {
    display: none;
  }

  #retryButton {
    display: none;
  }

  .print-summary-box {
    width: 800px !important;
    /* height: 900px !important; */
  }

  #print-order-details {
    width: 400px !important;
    /* height: 300px !important; */
  }

  #scrollableDiv {
    width: 100%;
    height: 100% !important;
    overflow: visible;
  }
  .only-show-on-print {
    display: flex !important;
  }
}
